import { fonts } from '@helpers/fonts';
import styled, { css } from 'styled-components';
/**
 * 20px / 28px
 */
export const LargeStyles = css `
  ${fonts.DaxPro.Regular};
  font-size: 20px;
  letter-spacing: normal;
  line-height: 28px;
  margin-bottom: 16px;
`;
/**
 * 18px / 28px
 */
export const RegularStyles = css `
  ${fonts.DaxPro.Regular};
  font-size: 18px;
  letter-spacing: normal;
  line-height: 28px;
  margin-bottom: 16px;
`;
/**
 * 16px / 26px
 */
export const SmallStyles = css `
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  letter-spacing: normal;
  line-height: 26px;
  margin-bottom: 16px;
`;
const Paragraph = styled.p `
  ${({ isLatestNewsWidget }) => isLatestNewsWidget
    ? css `
          min-height: 104px;
        `
    : null}
  
  ${({ align }) => align
    ? css `
          text-align: ${align};
        `
    : null}

  ${({ size }) => {
    switch (size) {
        case 'large':
            return LargeStyles;
        case 'regular':
            return RegularStyles;
        case 'small':
            return SmallStyles;
        default:
            break;
    }
}}
`;
export default {
    Paragraph,
    SmallStyles,
    RegularStyles,
    LargeStyles,
};
