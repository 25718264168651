import { imageUrl } from '@helpers/cloudinary';
import { Device } from '@helpers/media';
import { toBase64 } from '@helpers/to-base-64';
import React, { useState } from 'react';
import { ImageStyles } from './Image.styles';
const Image = (props) => {
    const [hasErrored, setHasErrored] = useState(false);
    const deviceWidths = [
        props.width ? props.width : -1,
        Device.MobileSmall,
        Device.MobileSmall * 2,
        Device.MobileSmall * 3,
        Device.MobileMedium,
        Device.MobileMedium * 2,
        Device.MobileMedium * 3,
        Device.Mobile,
        Device.Mobile * 2,
        Device.Mobile * 3,
        Device.MobileLarge,
        Device.MobileLarge * 2,
        Device.MobileLarge * 3,
        Device.Tablet,
        Device.Tablet * 2,
        Device.Tablet * 3,
        Device.TabletLarge,
        Device.TabletLarge * 2,
        Device.TabletLarge * 3,
        Device.DesktopSmall,
        Device.DesktopSmall * 2,
        Device.DesktopSmall * 3,
        Device.Desktop,
        Device.Desktop * 2,
        Device.Desktop * 3,
        Device.ActualDesktop,
        Device.ActualDesktop * 2,
        Device.ActualDesktop * 3,
    ]
        .filter((x) => x > 0)
        .sort((a, b) => (a < b ? -1 : 1));
    const srcSetUrls = deviceWidths
        .map((x) => `${imageUrl(props.src, `c_fill,w_${x}`)} ${x}w`)
        .join(', ');
    const src = imageUrl(props.src, `c_fill,w_${deviceWidths[deviceWidths.length - 1]}`);
    const sizes = props.sizes ? props.sizes : '100vw';
    const handleError = (e) => {
        if (hasErrored) {
            return;
        }
        setHasErrored(true);
        e.currentTarget.src = props.fallbackSrc ? props.fallbackSrc : '';
    };
    let components = null;
    switch (props.layout) {
        default:
        case 'responsive': {
            components = (React.createElement(ImageStyles.Responsive, { width: props.width, height: props.height, fullPaddingTop: props.fullPaddingTop },
                React.createElement("div", null),
                React.createElement(ImageStyles.Image, { role: props.role, alt: props.alt, decoding: props.preload ? 'sync' : 'async', loading: props.loading ? props.loading : 'lazy', objectFit: props.objectFit, objectPosition: props.objectPosition, draggable: props.draggable, sizes: sizes, srcSet: srcSetUrls, src: src, onError: handleError, 
                    /*// @ts-ignore */
                    fetchPriority: props.preload ? 'high' : 'auto' })));
            break;
        }
        case 'intrinsic': {
            const sizerSvg = `<svg width="${props.width}" height="${props.height}" xmlns="http://www.w3.org/2000/svg" version="1.1"/>`;
            components = (React.createElement(ImageStyles.Intrinsic, null,
                React.createElement("div", null,
                    React.createElement("img", { "aria-hidden": true, role: "presentation", src: `data:image/svg+xml;base64,${toBase64(sizerSvg)}` })),
                React.createElement(ImageStyles.Image, { role: props.role, alt: props.alt, decoding: props.preload ? 'sync' : 'async', loading: props.loading ? props.loading : 'lazy', objectFit: props.objectFit, objectPosition: props.objectPosition, draggable: props.draggable, sizes: sizes, srcSet: srcSetUrls, src: src, onError: handleError, 
                    /*// @ts-ignore */
                    fetchPriority: props.preload ? 'high' : 'auto' })));
            break;
        }
        case 'fixed': {
            components = (React.createElement(ImageStyles.Fixed, { width: props.width, height: props.height },
                React.createElement(ImageStyles.Image, { role: props.role, alt: props.alt, decoding: props.preload ? 'sync' : 'async', loading: props.loading ? props.loading : 'lazy', objectFit: props.objectFit, objectPosition: props.objectPosition, draggable: props.draggable, sizes: sizes, srcSet: srcSetUrls, src: src, onError: handleError, 
                    /*// @ts-ignore */
                    fetchPriority: props.preload ? 'high' : 'auto' })));
            break;
        }
        case 'fill': {
            components = (React.createElement(ImageStyles.Fill, null,
                React.createElement(ImageStyles.Image, { role: props.role, alt: props.alt, decoding: props.preload ? 'sync' : 'async', loading: props.loading ? props.loading : 'lazy', draggable: props.draggable, objectFit: props.objectFit, objectPosition: props.objectPosition, sizes: sizes, srcSet: srcSetUrls, src: src, onError: handleError, 
                    /*// @ts-ignore */
                    fetchPriority: props.preload ? 'high' : 'auto' })));
            break;
        }
    }
    return React.createElement(React.Fragment, null, components);
};
export default Image;
