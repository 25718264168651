import { Device, from } from '@helpers/media';
import styled from 'styled-components';
const Image = styled.img `
  position: absolute;
  height: 100%;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  display: block;
  text-indent: -999px;
  object-fit: ${({ objectFit }) => (objectFit !== undefined ? objectFit : 'initial')};
  object-position: ${({ objectPosition }) => objectPosition !== undefined ? objectPosition : undefined};

  @media ${from(Device.DesktopSmall)} {
    width: 100%;
    height: auto;
  }
`;
const Intrinsic = styled.div `
  height: 100%;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: 0;

  > div {
    box-sizing: border-box;
    display: block;
    max-width: 100%;
  }
`;
const Responsive = styled.div `
  display: block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: 0;

  > div {
    display: block;
    box-sizing: border-box;
    
    padding-top: ${({ width, height, fullPaddingTop }) => {
    if (fullPaddingTop || !height || !width) {
        return '100%';
    }
    const quotient = height / width;
    return isNaN(quotient) ? '100%' : `${quotient * 100}%`;
}};

  }
`;
const Fixed = styled.div `
  overflow: hidden;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
const Fill = styled.div `
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  margin: 0;
`;
export const ImageStyles = {
    Image,
    Intrinsic,
    Fixed,
    Responsive,
    Fill,
};
